.Container {
  position: relative;
  display: flex;
  flex-direction: column;
  outline: none;

  &:focus, &:focus-within {
    .Label {
      transform: translateY(-24px);
      color: var(--Color-Blue);
      background-color: #fff;
    }
  }

  &:has(input:autofill),
  &:has(input:-webkit-autofill) {
    .Label {
      transform: translateY(-24px);
      color: var(--Color-Blue);
      background-color: #fff;
    }
  }
}

.Label {
  position: absolute;
  pointer-events: none;
  transition: .2s cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: var(--Color-Black);
  left: 12px;
  font-size: 14px;
  line-height: 11px;
  font-weight: 600;
  display: block;
  top: 17px;

  &Active {
    transform: translateY(-24px);
    color: var(--Color-Gray-20);
    background-color: #fff;
  }
}

.Input {
  outline: none;
  color: var(--Color-Black);
  transition: all .2s;

  border: 1px solid var(--Color-Border);
  border-radius: 12px;
  background-color: #ffffff;

  font-family: var(--FontFamily);
  font-weight: var(--FontWeight-Regular);
  font-size: var(--FontSize);
  line-height: var(--FontLineHeight);
  margin: 0;

  padding: 10px 12px 12px;
  border-image: none;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0px 1px 2px rgb(41 41 64 / 7%);

  &:hover {
    border-color: var(--Color-Blue);
  }

  &:focus {
    border-color: var(--Color-Blue);
    box-shadow: 0 0 0 2px rgba(76, 110, 245, 0.4);
  }

  &Error {
    border-color: var(--Color-Red) !important;
    box-shadow: 0 0 0 2px rgba(250, 82, 82, 0.4) !important;
  }

  &Value {
    padding-right: 40px;
  }
}

.CLose {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 14px;
  fill: var(--Color-Gray);
  display: block;

  &:hover {
    fill: var(--Color-Black);
  }
}
