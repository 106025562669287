:root {
  --Color: #070717;
  --Color-Black: #101025;
  --Color-Red: #FA5252;
  --Color-Gray: #87879B;
  --Color-Gray-20: #53546E;
  --Color-Gray-100: #292940;
  --Color-Violet: #7950F2;
  --Color-Violet-24: rgba(121, 80, 242, 0.24);
  --Color-Violet-80: rgba(121, 80, 242, 0.8);
  --Color-Violet-200: #5F3DC4;
  --Color-Blue: #4C6EF5;
  --Color-Border: #D6D6DF;
  --Color-Bg: #F0F0F3;

  --FontSize: 15px;
  --FontLineHeight: 24px;

  --FontFamily:
          'Lato',
          helvetica,
          arial,
          -apple-system,
          system-ui,
          blinkmacsystemfont,
          'Segoe UI',
          'Helvetica Neue',
          sans-serif;

  --FontWeight-Regular: 400;
  --FontWeight-Medium: 600;
  --FontWeight-Bold: 700;
}


@font-face {
  font-family: 'Lato';
  src:
          local('Lato Regular'),
          local('Lato-Regular'),
          url('../public/fonts/latoregular.woff2') format('woff2'),
          url('../public/fonts/latoregular.woff') format('woff'),
          url('../public/fonts/latoregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src:
          local('Lato Medium'),
          local('Lato-Medium'),
          url('../public/fonts/latomedium.woff2') format('woff2'),
          url('../public/fonts/latomedium.woff') format('woff'),
          url('../public/fonts/latomedium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src:
          local('Lato Bold'),
          local('Lato-Bold'),
          url('../public/fonts/latobold.woff2') format('woff2'),
          url('../public/fonts/latobold.woff') format('woff'),
          url('../public/fonts/latobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

::-webkit-input-placeholder {
  color: var(--Color-Gray);
  font-weight: var(--FontWeight-Regular);
  font-size: 15px;
  line-height: 24px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: var(--FontFamily);
  color: var(--Color);
  font-size: var(--FontSize);
  line-height: var(--FontLineHeight);
  font-weight: var(--FontWeight-Regular);
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--Color-Bg);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: url('./pattern.png');
    background-size: 360px;
    opacity: .05;
  }
}
