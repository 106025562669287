$mobileBreak: 376px;

.Auth {
  flex: 1;
  padding: 48px 16px;
  position: relative;
  z-index: 1;
}

.Title {
  font-weight: var(--FontWeight-Bold);
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 4px;
}

.Text {
  font-weight: var(--FontWeight-Medium);
  font-size: 18px;
  line-height: 32px;
  color: #5F3DC4;
}

.Box {
  max-width: 1220px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.Wrapper {
  width: min(592px, 100%);
}

.Form {
  display: block;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px -2px rgba(41, 41, 64, 0.04), 0px 10px 15px -3px rgba(41, 41, 64, 0.08);
  border-radius: 16px;
  padding: 40px 32px;
  outline: none;
}

.Fields {
  margin: 24px 0;
}

.Field:not(:last-child) {
  margin-bottom: 16px;
}

.Logo {
  margin-bottom: 24px;
  display: block;
}

.Back {
  margin-bottom: 136px;
}

.Button {

}

@media screen and (max-width: $mobileBreak) {
  .Auth {
    padding: 16px 16px 42px;
  }

  .HideMobile {
    display: none;
  }

  .Back {
    margin-bottom: 44px;
  }

  .Logo {
    width: 131px;
    height: 28px;
  }

  .Form {
    padding: 20px 16px;
  }

  .Title {
    font-size: 22px;
    margin-bottom: 8px;
  }

  .Text {
    font-size: 16px;
    line-height: 24px;
  }

  .Fields {
    margin: 20px 0;
  }

  .Field:not(:last-child) {
    margin-bottom: 12px;
  }
}
