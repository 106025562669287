.Notification {
  $this: &;

  display: flex;
  align-items: flex-start;
  max-width: 1220px;
  background: var(--Color-Red);
  position: fixed;
  right: calc(((100vw - 1220px) / 2));
  top: 48px;
  z-index: 10;
  font-weight: var(--FontWeight-Bold);

  box-shadow: 0px 4px 6px -2px rgba(41, 41, 64, 0.04), 0px 10px 15px -3px rgba(41, 41, 64, 0.08);
  border-radius: 12px;

  animation: TranslateY .4s ease-out;
  color: #fff;
  padding: 11px 12px 13px;
  transform: translateY(0);
  opacity: 1;

  @media screen and (max-width: 1256px) {
    right: 16px;
    max-width: calc(100vw - 32px);
  }
  @media screen and (max-width: 376px) {
    top: 16px;
  }
}

.Action {
  cursor: pointer;
  margin-left: 40px;
  margin-top: 2px;
  width: 20px;
  flex: 0 0 auto;
  fill: #fff;
}



@keyframes TranslateY {

  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

