.Button {
  background-color: var(--Color-Violet);
  border: none;
  border-radius: 12px;
  color: #fff;
  outline: none;
  padding: 11px 16px 13px;
  text-align: center;

  cursor: pointer;

  font-family: var(--FontFamily);
  font-weight: var(--FontWeight-Medium);
  font-size: var(--FontSize);
  line-height: var(--FontLineHeight);

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  transition: background-color .2s;

  &:hover {
    background-color: var(--Color-Violet-80);
  }

  &:active {
    background-color: var(--Color-Violet-200);
  }


  &.Loading,
  &[disabled] {
    pointer-events: none;
    background-color: var(--Color-Violet-24);
  }

  &.Fluid {
    width: 100%;
    text-align: left;
  }

  &.Link {
    color: var(--Color-Gray-100);
    font-weight: 700;
    flex-direction: row-reverse;
    background-color: rgba(255,255,255, 48%);
    padding: 7px 16px 9px;

    &:hover {
      background-color: rgba(255,255,255, 64%);
    }

    &:active {
      background-color: rgba(255,255,255, 80%);
    }

    .Icon {
      fill: var(--Color-Gray-100);
      margin-right: 8px;
    }
  }
}

.Icon {
  display: block;
  fill: #fff;

  &Right {
    transform: rotate(180deg);
  }
}
