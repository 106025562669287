.ContentLoader {
  $this: &;

  display: flex;
  pointer-events: none;
  position: relative;
  z-index: 1;

  justify-content: center;
  align-items: center;
}

.Wrapper {
  position: relative;

  display: inline-block;

  width: 14px;
  height: 14px;
}

.Area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.Title {
  margin: 16px 0;
}

.Spinner {
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;

  width: 100%;
  height: 100%;

  animation: GHrotator 1.4s linear infinite;

  opacity: 1;
}

.CirclePath {
  transform-origin: center;
  animation: GHdash 1.4s ease-in-out infinite;

  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  stroke: #ffffff;
  stroke-width: 12;

}

@keyframes GHrotator {

  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes GHdash {

  0% {
    stroke-dashoffset: 187;
  }

  50% {
    transform: rotate(135deg);
    stroke-dashoffset: 46.75;
  }

  100% {
    transform: rotate(450deg);
    stroke-dashoffset: 187;
  }
}
